import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import { FaMicrosoft } from "react-icons/fa";

const Venetians = () => (
  <React.Fragment>
    <Helmet>
      <title>Venetians | Melbourne | Brisbane | Birk</title>
      <meta
        property="og:title"
        content="Venetians | Melbourne | Brisbane | Birk"
      />
      <meta
        name="keywords"
        content="Roller Blinds, Roman Blinds, Curtains, Shutters, Venetians, External Range, Automation, Honeycomb Blinds"
      />
      <meta
        name="description"
        content="Birk offers range of quality Venetians. We are operated in Melbourne and Brisbane"
      />
      <meta
        property="og:description"
        content="Birk offers range of quality Venetians. We are operated in Melbourne and Brisbane"
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="http://birk.itechnotion.com/venetians" />
      <link rel="canonical" href="http://birk.itechnotion.com/venetians" />
    </Helmet>
    <Header />
    <section className="product-section">
      <div className="container-fluid all-section">
        <div className="row">
          <div className="col-md-4 col-lg-3">
            <div className="heading">
              <h2>Venetians</h2>
            </div>
            <div className="product-menu">
              <ul>
                <li className="mb-2">
                  <Link to="/roller-blinds">Roller Blinds</Link>
                </li>
                <li className="mb-2">
                  <Link to="/roman-blinds">Roman Blinds</Link>
                </li>
                <li className="mb-2">
                  <Link to="/curtains">Curtains</Link>
                </li>
                <li className="mb-2">
                  <Link to="/shutters">Shutters</Link>
                </li>
                <li className="mb-2">
                  <Link to="/venetians">
                    <span className="font-weight-bold">Venetians</span>
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/external-range">External Range</Link>
                </li>
                <li className="mb-2">
                  <Link to="/automation">Automation</Link>
                </li>
                <li className="mb-2">
                  <Link to="/honeycomb">Honeycomb</Link>
                </li>
              </ul>
              <div className="links-list sidebar-links">
                <Link to="/gallery" className="links-list-item">
                  <FaMicrosoft />
                  &nbsp; Go to gallery
                </Link>
              </div>
            </div>
          </div>

          <div className="col-md-8 col-lg-9 product-item mt-2">
            <div className="col-12 col-md-12 mb-4">
              <div className="pro-start-content">
                <div className="product-inner">
                  <p className="text-justify">
                    If shutters and customised curtains are the stars of window
                    coverings, venetian blinds are the loyal veterans. Usually
                    the go-to choice for studies, libraries and bathrooms,
                    venetian blinds can easily be adjusted according to privacy
                    and lighting needs.
                  </p>
                  <p className="text-justify">
                    Birk understands no two rooms, and no two budgets, are the
                    same. That’s why we offer a range of aluminium and timber
                    venetian blinds – all handpicked for their quality and
                    durability. After all, they need to withstand years of sun
                    and heat exposure.
                  </p>
                  <p className="text-justify">
                    Inferior venetian blinds are particularly prone to warping
                    and sagging but Birk has built its 30-year history on
                    sourcing the finest materials upon which to rest our
                    reputation.
                  </p>
                  <p>
                    We’ve also selected a myriad of hues to match your room’s
                    décor and existing fittings.
                  </p>
                  <p className="text-justify mb-3">
                    Your Birk venetian blinds can be customised in a range of
                    affordable and fashionable fabrics.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-5 col-sm-12 mt-2 mb-4">
              <div className="d-flex flex-wrap">
                <div className="mb-2 mr-3">
                  <StaticImage
                    src="../images/venetians-icon1.png"
                    alt=""
                    className="img-fluid"
                  />
                  <p className="text-center font_style">
                    50mm
                    <br />
                    Timberstyle
                  </p>
                </div>
                <div className="mb-2 mr-3">
                  <StaticImage
                    src="../images/venetians-icon2.png"
                    alt=""
                    className="img-fluid"
                  />
                  <p className="text-center font_style">
                    65mm
                    <br />
                    Timberstyle
                  </p>
                </div>
                <div className="mb-2 mr-3">
                  <StaticImage
                    src="../images/venetians-icon3.png"
                    alt=""
                    className="img-fluid"
                  />
                  <p className="text-center font_style">Aluminium</p>
                </div>
              </div>
            </div>
            <div className="why-list why-list2 ml-4">
              <ul>
                <li>Modern & functional</li>
                <li>Easy to operate</li>
                <li>Privacy</li>
                <li>Light control & ventilation</li>
                <li>Control blades to any position to manage sunlight</li>
              </ul>
            </div>

            <div className="col-12 col-md-12 mb-4">
              <StaticImage
                src="../images/bvenetians1.png"
                alt=""
                className="img-fluid"
              />
            </div>

            <div className="col-12 col-md-12 mb-4">
              <StaticImage
                src="../images/bvenetians2.png"
                alt=""
                className="img-fluid"
              />
            </div>

            <div className="col-12 col-md-12 mb-4">
              <StaticImage
                src="../images/bvenetians3.png"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </React.Fragment>
);
export default Venetians;
